<template>
    <AddShippingMethod />
</template>

<script>
import {defineComponent, onMounted} from "vue";
import AddShippingMethod from "../components/AddShippingMethod";
import {setPageHeader, setPageHistory} from "../../../../core/helpers/toolbar";
import {useStore} from "vuex";
export default defineComponent({
    components: {
        AddShippingMethod
    },
    setup() {
        const store = useStore()
        onMounted(() => {
            setPageHeader({
                title: 'message.ADD message.SHIPPING_METHODS',
                actionButton: null,
                breadCrumbs: [
                    { name: "message.HOME", link: "/dashboard"},
                    { name: "message.SYSTEM", link: "#"},
                    { name: "message.SETTINGS", link: "/system/settings"},
                    { name: "message.SHIPPING_METHODS", link: "/system/settings/shipping-methods"},
                    { name: "message.ADD message.SHIPPING_METHODS"}
                ]
            });
            setPageHistory({});
            store.commit('SET_SEARCH_DATA', {});
        });
    }
});
</script>