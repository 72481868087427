import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card card-p" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-7" }
const _hoisted_4 = { class: "col-md-1 text-center" }
const _hoisted_5 = { class: "col-md-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InnerLoader = _resolveComponent("InnerLoader")!
  const _component_ShippingMethodSites = _resolveComponent("ShippingMethodSites")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_ShippingMethodPostCodes = _resolveComponent("ShippingMethodPostCodes")!
  const _component_ShippingMethodCustomerGroups = _resolveComponent("ShippingMethodCustomerGroups")!
  const _component_ShippingMethodCustomers = _resolveComponent("ShippingMethodCustomers")!
  const _component_ShippingMethodIntegration = _resolveComponent("ShippingMethodIntegration")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_ShippingMethodBasicInfo = _resolveComponent("ShippingMethodBasicInfo")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    model: _ctx.shippingMethod,
    ref: "addShippingMethodForm",
    class: "shipping-method-form"
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_InnerLoader, {
          loading: _ctx.loader,
          innerLoading: true
        }, null, 8, ["loading"]),
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, [
            _createVNode(_component_el_tabs, {
              modelValue: _ctx.activeTab,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeTab = $event)),
              "tab-position": _ctx.top
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_tab_pane, {
                  label: _ctx.$t('message.SITES'),
                  name: "first"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ShippingMethodSites, {
                      onStore: _ctx.dataHandler,
                      ref: "sitesFormData"
                    }, null, 8, ["onStore"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_tab_pane, {
                  label: _ctx.$t('message.POST_CODES'),
                  name: "second"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ShippingMethodPostCodes, {
                      onStore: _ctx.dataHandler,
                      ref: "postCodeFormData"
                    }, null, 8, ["onStore"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_tab_pane, {
                  label: _ctx.$t('message.CUSTOMER_GROUPS'),
                  name: "third"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ShippingMethodCustomerGroups, {
                      onStore: _ctx.dataHandler,
                      ref: "customerGroupFormData"
                    }, null, 8, ["onStore"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_tab_pane, {
                  label: _ctx.$t('message.CUSTOMERS'),
                  name: "forth"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ShippingMethodCustomers, {
                      onStore: _ctx.dataHandler,
                      ref: "customerFormData"
                    }, null, 8, ["onStore"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_tab_pane, {
                  label: _ctx.$t('message.INTEGRATION'),
                  name: "fifth"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ShippingMethodIntegration, {
                      onStore: _ctx.dataHandler,
                      ref: "integrationFormData"
                    }, null, 8, ["onStore"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["modelValue", "tab-position"])
          ]),
          _createVNode("div", _hoisted_4, [
            _createVNode(_component_el_divider, { direction: "vertical" })
          ]),
          _createVNode("div", _hoisted_5, [
            _createVNode(_component_ShippingMethodBasicInfo, {
              onSubmitHandlerForm: _ctx.submitHandlerForm,
              onResetForm: _ctx.resetForm,
              onStore: _ctx.dataHandler,
              ref: "basicFormData"
            }, null, 8, ["onSubmitHandlerForm", "onResetForm", "onStore"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["model"]))
}